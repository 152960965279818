<template>
  <v-container>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                      v-show="!zoneDetails.hasOwnProperty('image')"
                      height="260px"
                      class="rounded-t-lg"
                      type="image"
                  ></v-skeleton-loader>-->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.hasOwnProperty('image') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.hasOwnProperty('image') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              {{
              bookingDetails.hasOwnProperty("zone") &&
                bookingDetails.zone.hasOwnProperty("zoneName")
                ? bookingDetails.zone.zoneName
                : ""
            }}
            </div>
          </div>
          <v-card-text>
            <v-container fluid pa-0>
              <v-row no-gutters class="mt-0">
                <v-col class="pa-1">
                  <p class="black--text">
                    Here's a estimate for your active session at
                    {{
                bookingDetails.hasOwnProperty("zone") &&
                  bookingDetails.zone.hasOwnProperty("zoneName")
                  ? bookingDetails.zone.zoneName
                  : ""
              }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-1 mx-0">
                <v-col cols="4" class="text-left pa-1">
                  <p class="booking_date_header mb-0">Started At:</p>
                </v-col>
                <v-col class="text-right pa-1">
                  <p class="booking_date mb-0">
                    {{
                bookingDetails.hasOwnProperty("booking") &&
                  bookingDetails.booking.hasOwnProperty("entrytime")
                  ? formatToReceiptDateTime(
                    bookingDetails.booking.entrytime
                  )
                  : ""
              }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mx-0">
                <v-col cols="4" class="text-left pa-1">
                  <p class="booking_date_header">End At:</p>
                </v-col>
                <v-col class="text-right pa-1">
                  <p class="booking_date">
                    {{
                bookingDetails.hasOwnProperty("booking") &&
                  bookingDetails.booking.hasOwnProperty("exittime")
                  ? formatToReceiptDateTime(
                    bookingDetails.booking.exittime
                  )
                  : ""
              }}
                  </p>
                </v-col>
              </v-row>
              <v-container class="text-center">
                <v-row no-gutters>
                  <v-col cols="12" class="px-3 text-center " style="font-size: 12px">
                    <v-row no-gutters class="pt-2 ">
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text">
                          Parking
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          {{ '$ ' }}
                          {{ bookingDetails.hasOwnProperty("booking") &&
              bookingDetails.booking.hasOwnProperty('quotedRegularPrice') &&
              bookingDetails.booking.hasOwnProperty('totalPrice')
              ? bookingDetails.booking.quotedRegularPrice != null ?
                Number(bookingDetails.booking.quotedRegularPrice).toFixed(2) :
                Number(bookingDetails.booking.estimatedPrice).toFixed(2)
              : "-" }}

                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters
                      v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'validatedAmount') && Object.hasOwnProperty.call(bookingDetails.booking, 'quotedRegularPrice') && bookingDetails.booking.quotedRegularPrice != null && bookingDetails.booking.validatedAmount != '-' && bookingDetails.booking.validatedAmount > 0"
                      class="pt-2 ">
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small;color: #f2555c;font-weight: bold;">
                          Discount
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small;color: #f2555c;font-weight: bold;" class=" text-right">
                          {{
              bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty("validatedAmount")
                ? "( $ " + Number(bookingDetails.booking.validatedAmount).toFixed(2) + " )"
                : "( - )"
            }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0 ">
                      <v-col cols="4" class="text-left">
                        <v-row no-gutters>
                          <v-col cols="11">
                            <p style="font-size: small" class="black--text">
                              Service Fee
                            </p>
                          </v-col>
                          <v-col cols="1">
                            <v-tooltip bottom v-model="show">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                                  @click="show = !show" v-on="on">info</v-icon>
                              </template>
                              <span>This fee helps cover the use of the technology that provides a better parking
                                experience.</span>
                            </v-tooltip>
                          </v-col>

                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          {{ bookingDetails.hasOwnProperty("booking") &&
              bookingDetails.booking.hasOwnProperty('estimatedFee')
              ? '$ ' + Number(bookingDetails.booking.estimatedFee).toFixed(2)
              : "-" }}
                        </p>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="mt-2">
                      <v-col cols="6" class="text-left">
                        <p style="font-weight: bold;color:#F2555C;font-size:15px">
                          Purchase Total <sup>*</sup>
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                          {{ bookingDetails.hasOwnProperty("booking") &&
              bookingDetails.booking.hasOwnProperty('estimatedTotal')

              ? '$ ' + Number(bookingDetails.booking.estimatedTotal).toFixed(2)
              : "-" }}

                        </p>
                      </v-col>
                      <p>
                        *Includes state and local taxes of {{ bookingDetails.hasOwnProperty("booking") &&
              bookingDetails.booking.hasOwnProperty('estimatedTax')
              ? '$ ' + Number(bookingDetails.booking.estimatedTax).toFixed(2)
              : "" }}
                      </p>
                    </v-row>
                    <v-row no-gutters class="">
                      <v-col class="text-left">
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-card flat class="pa-0 ma-0" width="100%" v-if="lprImagePath != undefined && lprImagePath != null && lprImagePath != ''">
                <v-card-text style="border-radius: 10px !important;" class="pa-0 ma-0 pb-5">
                  <v-img aspect-ratio="1" class="grey lighten-2" lazy-src="" width="100%" height="162px"
                    :src="lprImagePath" transition="fade-transition">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-skeleton-loader class="mx-auto" width="100%" type="image"></v-skeleton-loader>
                      </v-row>
                    </template>
                  </v-img>
                  <v-container pa-0 fluid style="background: rgba(68, 68, 68, 0.1);padding-top: 14px !important; border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;">
                    <v-row no-gutters>
                      <v-col class="pl-3 text-left pb-4" cols="5">
                        <!-- custom css classes grey-detail-text and black detail-text -->
                        <p class="grey-detail-text" style="margin-bottom: 0">Contact No</p>
                        <p class="black-detail-text">{{ '+' + contactNo }}</p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions class="text-center pt-0 mt-0 pb-9">
            <v-container class="pa-0" fluid>
              <!-- <v-row>
                      <v-col cols="12">
                        <a class="text-caption" @click="navigateToReceipt()">View Receipt</a>
                      </v-col>
                    </v-row> -->
              <v-col cols="12" class="pa-0 mb-3"
                v-if="iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'apple_pay') && bookingDetails.booking.paymentServices.apple_pay == '1'">
                <apple-pay width="100%" height="50px" :typeOfBooking="'promisetopay'"
                  :ios-device="iosDevice && Object.hasOwnProperty.call(bookingDetails.booking, 'bid') && Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'contact') && Object.hasOwnProperty.call(bookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'locationCode')"
                  :bookingId="bookingDetails.booking.bid" :contact="bookingDetails.user.contact"
                  :locationCode="bookingDetails.zone.locationCode"
                  :startAt="bookingDetails.booking.startAt"></apple-pay>
              </v-col>
              <v-col cols="12" class="pa-0 mb-3"
                v-if="!iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'google_pay') && bookingDetails.booking.paymentServices.google_pay == '1'">
                <google-pay width="100%" height="50px"
                  :ios-device="iosDevice && Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'paymentServices') && Object.hasOwnProperty.call(bookingDetails.booking.paymentServices, 'google_pay') && bookingDetails.booking.paymentServices.google_pay == '1' && Object.hasOwnProperty.call(bookingDetails.booking, 'bid') && Object.hasOwnProperty.call(bookingDetails, 'user') && Object.hasOwnProperty.call(bookingDetails.user, 'contact') && Object.hasOwnProperty.call(bookingDetails.booking, 'startAt') && Object.hasOwnProperty.call(bookingDetails, 'zone') && Object.hasOwnProperty.call(bookingDetails.zone, 'locationCode')"
                  :typeOfBooking="'promisetopay'" :bookingId="bookingDetails.booking.bid"
                  :contact="bookingDetails.user.contact" :locationCode="bookingDetails.zone.locationCode"
                  :startAt="bookingDetails.booking.startAt"></google-pay>
              </v-col>
              <v-row no-gutters>
                <v-col>
                  <v-btn class="add_licence_plate_btn white--text" elevation="20" rounded @click="makePayment">PROCEED
                    TO
                    PAYMENT</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="text-center pr-8 pl-8 pt-2">
                <v-col>
                  <p style="font-size: 11px">
                    Make payment before, {{ citationDate }} to avoid citation
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="alertDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/lib/EventBus";
import GooglePay from '@/views/GooglePay.vue'
import ApplePay from "@/views/ApplePay.vue";
import APIHelper from "../apiHelper";
import rules from "@/utils/rules";
import { formatDateTime } from "@/utils/formatDateTime";
import { format, addDays } from "date-fns";
// import { format } from "date-fns";
export default {
  data: (vm) => ({
    lprImagePath: null,
    show: false,
    alertDialog: false,
    alertMsg: "",
    time: null,
    createAcc: false,
    iosDevice: false,
    Timemenu: false,
    Datemenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    rules: rules,
    licencePlate: "",
  }),
  components: { ApplePay, GooglePay },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getMobileOperatingSystem() === 'iOS' ? vm.iosDevice = true : vm.iosDevice = false;
      vm.getLPRImages()
      let searchParams = window.location.search.split("=");
      let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
      let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
      switch (searchParamKey) {
        case "?zcode":
          {
            vm.getZoneDetails(searchParamKey, searchParamValue);
          }
          break;
        case "?gateid":
          //await self.getZoneDetails(searchParamKey, searchParamValue);
          break;
      }
    });
  },
  destroyed() {
    EventBus.$off('SQUARE_PAY_PROMISETOPAY');
  },
  mounted() {
    var self = this
    EventBus.$on('SQUARE_PAY_PROMISETOPAY', async (tokenResult, verfiedToken, paymentType) => {
      await self.squarePay(tokenResult, verfiedToken, paymentType)
    })
    self.getLPRImages()

  },
  methods: {
    async getLPRImages() {
      try {
        let postData = {
          refno: this.bookingDetails?.booking?.linkedTxnRefno != undefined && this.bookingDetails?.booking?.linkedTxnRefno != null && this.bookingDetails?.booking?.linkedTxnRefno != '' ? this.bookingDetails?.booking?.linkedTxnRefno : this.bookingDetails?.booking?.bid,
        };
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        let resultData = await APIHelper(
          "POST",
          "/getLprImages",
          postData
        );
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        let lprImagesData = resultData?.data?.data;
        if (lprImagesData != undefined && lprImagesData != null && lprImagesData.length != 0) {
          this.lprImagePath = lprImagesData.find(
            (a) => a.gateType === "IN"
          )?.image_path;
        }
        this.$store.commit("SET_LOADING_STATUS", {
          property: "false",
          pending: true,
        });
      } catch (error) {

        console.log(error);
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
      }
    },
    async squarePay(tokenResult, verfiedToken, paymentType) {
      let cardData = {};
      if (
        this.bookingDetails != null &&
        Object.hasOwnProperty.call(this.bookingDetails, "zone")
      ) {
        cardData = {
          userDevice: "2",
          orderType: "3",
          entry: [
            {
              locationCode: this.bookingDetails.zone.locationCode,
              startAt: this.bookingDetails.booking.startAt,
              quantity: "1",
            },
          ],
          paymentType: paymentType,
          billingDetails: tokenResult?.details?.billing,
          verification_token: verfiedToken,
          source_id: tokenResult.token,
          phoneNumber: "+" + this.bookingDetails.user.contact,
          email: tokenResult?.details?.shipping?.contact?.email || "",
          captchaVerify: {
            token: this.generateRandomToken(),
            sitekey: this.getSiteKey,
            legacy: true
          }
        };
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        try {
          var addCard = await APIHelper(
            "POST",
            "/api/v1/payment/addCard/" + this.bookingDetails?.booking?.bid,
            cardData
          );
          if (addCard?.data?.status == false) {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            this.gPayBtnLoading = false;
            this.alertMsg =
              addCard?.data?.message || "Error in authorizing the payment";
            this.alertIcon = "mdi-alert-outline";
            this.alertDialog = true;
          } else {
            this.$store.commit("SET_LOADING_STATUS", {
              property: "APP",
              pending: false,
            });
            this.gPayBtnLoading = false;
            //url segment is app mixin computed property
            let url =
              window.location.origin + '/g/' + this.bookingDetails?.booking?.bid;
            window.location.replace(url);
          }
        } catch (error) {
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.gPayBtnLoading = false;
          console.log(error);
        }
      }
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    },
    formatToReceiptDateTime(dateTime) {
      let dateTimeArr =
        dateTime != null && dateTime != undefined && dateTime != ""
          ? formatDateTime(dateTime)
          : "";
      return dateTimeArr != ""
        ? dateTimeArr[1] + " " + dateTimeArr[2] + ", " + dateTimeArr[0]
        : "";
    },
    navigateToReceipt() {
      this.$router.push({ path: "/receipt" });
    },
    formatCitationDate(time) {
      let utcDate = new Date(time * 1000).toUTCString();
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1];
      let year = utcDate.split(",")[1].split(" ")[3]; // 27
      let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
      let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
      let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
      formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
      let amOrpm = Number(hr) >= 12 ? "PM" : "AM"; //PM
      return (
        month +
        " " +
        date +
        " " +
        year +
        " " +
        formatHr +
        ":" +
        min +
        " " +
        amOrpm
      ); //Jan 27, 01:59 PM
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
    },
    makePayment() {
      this.$router.replace("/addpayment");
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  computed: {
    ...mapGetters({
      zoneDetails: "getterZoneDetails",
      odDetails: "getterOdDetails",
      casinoDetails: "getterCasinoDetails",
      templateFlags: "getterTemplateFlags",
      bookingDetails: "getterBookingDetails",
    }),
    contactNo() {
      return this.bookingDetails?.user?.contact || "-";
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    citationDate() {
      let createdAt = this.bookingDetails?.booking?.createdAt
        ? this.bookingDetails?.booking?.createdAt
        : new Date();
      let citationDate = format(
        addDays(new Date(createdAt), this.bookingDetails?.zone?.p2pCloseDuration ? this.bookingDetails?.zone?.p2pCloseDuration : 2),
        "MMM dd yyyy hh:mm aa "
      );
      return citationDate;
    },

    disableSubmitBtn() {
      let disabled =
        this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.time == null ||
        this.time === "" ||
        this.date === null ||
        this.date === "";
      disabled =
        this.licencePlate != "" && this.licencePlate != null
          ? disabled || /^[a-z0-9]+$/i.test(this.licencePlate) === false
          : disabled;
      return disabled;
    },
    getSiteKey(){
      return  process.env.VUE_APP_CAPTCHA_SITE_KEY
    }
  },
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 60px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 45px;
  width: 100%;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.booking_content {
  position: absolute;
  bottom: 18px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.thankyou_header {
  position: absolute;
  bottom: 20px;
  left: 16px;
  font-size: 24px;
  font-weight: bolder;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}
.black-detail-text {
  font-size: 14px;
  font-weight: bold;
}
.grey-detail-text {
  font-size: 14px;
  font-weight: 400;
}

</style>